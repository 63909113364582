import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Logo = () => {
  const { wp } = useStaticQuery(graphql`
  query {
    wp {
      globalSettings {
        globalSettings {
          logo {
            sourceUrl
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 180,
                  layout: FIXED
                  placeholder: TRACED_SVG
                )
              }
            }
          }
        }
      }
    }
  }
`)
  const image = getImage(wp.globalSettings.globalSettings.logo.localFile)
    if (image) return <GatsbyImage image={image} alt="Bất động sản Alphaland" />
  return null
}

export default Logo
