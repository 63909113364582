import React from "react"
import { StaticQuery, graphql } from "gatsby"
import MenuItem from "../MenuItem"

const MENU_New= graphql`
query GETNEWSMENU {
  allWpMenuItem(filter: { locations: { eq: TIN_TUC } }) {
    nodes {
      id
      label
      url
      title
      target
      cssClasses
    }
  }
  wp {
    generalSettings {
      url
    }
  }
}
`

const MenuNew = () => {
  return (
    <StaticQuery
      query={MENU_New}
      render={(data) => {
        if (data.allWpMenuItem.nodes) {
          const menuItems = data.allWpMenuItem.nodes
          const wordPressUrl = data.wp.generalSettings.url
          return (
            <ul className="navbar-nav ml-auto">

                      {
                        menuItems &&
                        menuItems.map((prop, index) => (
                          <li key={index}>
                            <MenuItem key={prop.id} menuItem={prop} wordPressUrl={wordPressUrl} />
                          </li>
                        ))
                      }
                    </ul>
          )
        }
        return null
      }}
    />
  )
}

export default MenuNew
