import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import ScrollUpButton from "react-scroll-up-button"
import Menu from "./Menu"
import Footer from "./Footer"

const Layout = ({ isHomePage, children }) => {
  const {
    wp: {
      globalSettings: {
        globalSettings: {
          address,
          copyright,
          email,
          tawkLiveChat,
          uhChat,
          phone,
          googleTagManager,
          googleAnalytics,
        },
      },
    },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        globalSettings {
          globalSettings {
            address
            copyright
            email
            tawkLiveChat
            phone
            googleTagManager
            googleAnalytics
            uhChat
          }
        }
      }
    }
  `)
  return (
    <>
      <Helmet>
        {googleTagManager &&
          (googleTagManager.startsWith("<script") ? (
            googleTagManager
          ) : (
            <script defer type="text/javascript">
              {googleTagManager}
            </script>
          ))}
        {tawkLiveChat &&
          (tawkLiveChat.startsWith("<script") ? (
            tawkLiveChat
          ) : (
            <script defer type="text/javascript">
              {tawkLiveChat}
            </script>
          ))}
      </Helmet>

      <Menu />
      <main>{children}</main>
      <Footer />
      <ScrollUpButton style={{ width: 40, height: 40 }} />
    </>
  )
}

export default Layout
