import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import Logo from "./Logo"
import { useLocation } from '@reach/router';

function listToTree(list) {
  var map = {},
    node,
    roots = [],
    i

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i // initialize the map
    list[i].children = [] // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i]
    if (node.parentId) {
      // if you have dangling branches check that map[node.parentId] exists
      list[map[node.parentId]].children.push(node)
    } else {
      roots.push(node)
    }
  }
  return roots
}

const Menu = () => {
  const data = useStaticQuery(graphql`
    query GETMAINMENU {
      allWpMenuItem(filter: { locations: { eq: TOP } }) {
        nodes {
          id
          label
          url
          title
          parentId
          target
          cssClasses
        }
      }
    }
  `)
  const location = useLocation();
  const menuItems = listToTree(data.allWpMenuItem.nodes)
  return (
    <header>
      <nav className="navbar navbar-expand-lg fixed-top navbar-light bg-light">
        <div className="container">
          <Link to="/" className="navbar-brand">
            <Logo />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              {menuItems &&
                menuItems.map((prop, index) => (
                  <li
                    className={prop.cssClasses + " nav-item dropdown"}
                    key={"nav-item" + index}
                  >
                    {prop.children && prop.children.length > 0 ? (
                      <a
                        rel="noopener noreferrer"
                        data-toggle="dropdown"
                        className="btn dropdown-toggle dropdown-toggle-split"
                      >
                        {prop.label}
                      </a>
                    ) : (
                      <a className={"dropdown-item " +  (prop.url === location.pathname? "active" : "")} href={prop.url}>
                        {prop.label}
                      </a>
                    )}
                    {prop.children && prop.children.length > 0 && (
                      <div className="dropdown-menu">
                        {prop.children.map((child, index) => {
                          return (
                            <div
                              className="nav-item-sub"
                              key={"sub-menu" + index}
                            >
                              <a
                                className={
                                  "dropdown-item " +
                                  (child.url === location.pathname ? "active" : "")
                                }
                                href={child.url}
                              >
                                {child.label}
                              </a>
                            </div>
                          )
                        })}
                      </div>
                    )}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Menu
