import React from "react"
import MenuPro from "./Footer/MenuProject"
import MenuAbout from "./Footer/MenuAbout"
import MenuNew from "./Footer/MenuNew"
import { StaticQuery, graphql } from "gatsby"
import Logo from "./Logo"
import parse from "html-react-parser"
const global_Settings = graphql`
  query FooterQuery {
    wp {
      globalSettings {
        globalSettings {
          address
          email
          phone
          googleTagManager
          googleAnalytics
          copyright
          tawkLiveChat
          uhChat
          favicon {
            sourceUrl
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 180, placeholder: TRACED_SVG)
              }
            }
          }
          logo {
            sourceUrl
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 180, placeholder: TRACED_SVG)
              }
            }
          }
        }
      }
    }
  }
`

const Footer = () => {
  return (
    <StaticQuery
      query={global_Settings}
      render={data => {
        if (data.wp.globalSettings.globalSettings) {
          const item = data.wp.globalSettings.globalSettings
          const content = item.copyright
          const uhChat = item.uhChat
          return (
            <>
              <footer>
                <div className="container">
                  <div className="row">
                    <div className="col-md-3 col-xs-12">
                      <div className="item">
                        <div className="logo">
                          {/* <FluidImage image={item.logo} alt="logo"/> */}
                          {/* <img src={Logo}  alt="logo" /> */}
                          <Logo></Logo>
                        </div>
                        <p>
                          <i className="fa fa-map-marker"></i>Địa chỉ:{" "}
                          {item.address}
                        </p>
                        <p>
                          <i className="fa fa-phone" aria-hidden="true"></i>
                          Hotline: <span>{item.phone}</span>
                        </p>
                        <p>
                          <i className="fa fa-envelope" aria-hidden="true"></i>
                          Email: <span>{item.email}</span>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <div className="item">
                        <h3>Công ty Alphaland</h3>
                        <MenuAbout />
                      </div>
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <div className="item">
                        <h3>Tìm hiểu thêm</h3>
                        <MenuPro />
                      </div>
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <div className="item">
                        <h3>Nhận tin dự án</h3>
                        <MenuNew />
                      </div>
                    </div>
                  </div>
                  <div className="cpr text-center">
                    <div>{parse(content)}</div>
                  </div>
                </div>              
              </footer>

              {uhChat && <script src={uhChat}></script>}
            </>
          )
        }
        return null
      }}
    />
  )
}

export default Footer
